import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import Onboard from "../ui/Onboard";
import Header from "../ui/layout/Header";
import Footer from "../ui/layout/Footer";
import { TextAreaShadCN } from "../ui/shadcn/TextAreaShadCN";
import { Button } from "../ui/shadcn/Button";
import { Input } from "../ui/shadcn/Input";
import useStore from "../../store/store";

const DEFAULT_USERNAME = "";
const DEFAULT_BIO = "";
const DEFAULT_PFP = null;
const DEFAULT_STEP = 2;
const DEFAULT_IS_MODAL_OPEN = false;

const Settings = () => {
    const [username, setUsername] = useState(DEFAULT_USERNAME);
    const [bio, setBio] = useState(DEFAULT_BIO);
    const [pfp, setPfp] = useState(DEFAULT_PFP);
    const [altStep, setAltStep] = useState(DEFAULT_STEP);
    const [isModalOpen, setIsModalOpen] = useState(DEFAULT_IS_MODAL_OPEN);
    const [initialValues, setInitialValues] = useState({ username: DEFAULT_USERNAME, bio: DEFAULT_BIO, pfp: DEFAULT_PFP });
    const { isUserLoading, user, getUser } = useStore((state) => ({
        isUserLoading: state.isUserLoading,
        user: state.user,
        getUser: state.getUser
    }));
    const location = useLocation();
    const redirectPath = location.state?.redirectPath || "/feed"; // Default to /feed if none
    const isToReadModalOpen = location.state?.isToReadModalOpen || false;
    const isReadingModalOpen = location.state?.isReadingModalOpen || false;
    const isReviewModalOpen = location.state?.isReviewModalOpen || false;



    useEffect(() => {
        getUser();
    }, []);

    useEffect(() => {
        if (!isUserLoading) {
            setUsername(user?.username);
            setBio(user?.bio);
            setPfp(user?.pfp);
            setInitialValues({ username: user?.username, bio: user?.bio, pfp: user?.pfp });
            if (!user?.onboarded) {
                setAltStep(1);
                setIsModalOpen(true);
            }
        }
    }, [isUserLoading]);

    const handleChange = (setter, limit) => (e) => {
        if (e.target.value.length <= limit) {
            setter(e.target.value);
        }
    };

    const handleSubmit = async (e) => {
        try {
            if (username.trim() === "") {
                toast.error("Username cannot be empty");
                return;
            }
            const formData = new FormData();
            let hasChanges = false;
            if (initialValues.username !== username) {
                if (!/^[a-zA-Z0-9]*$/.test(username)) {
                    toast.error("Username cannot contain spaces or special characters");
                    setUsername(user?.username);
                    return;
                }
                const { data } = await axios.get("/api/e/v1/auth/check_username", {
                    params: {
                        username
                    }
                });
                if (data.exists) {
                    toast.error("Username already exists");
                    setUsername(user?.username);
                    return;
                } else {
                    formData.append("username", username);
                    hasChanges = true;
                }
            }
            if (initialValues.bio !== bio) {
                formData.append("bio", bio);
                hasChanges = true;
            }
            if (pfp !== null) {
                formData.append("pfp", pfp);
                hasChanges = true;
            }
            if (hasChanges) {
                await axios.post("/api/e/v1/auth/update_user", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                toast.success("Changes submitted successfully");
                setInitialValues({ username, bio, pfp });
                getUser();
            }
        } catch (err) {
            console.error(err);
            toast.error("Error submitting changes");
        }
    };

    return (
        <div className="page flex justify-center items-center">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <Header />
                <div className="flex flex-col md:flex-row items-center justify-center w-full">
                    <div className="flex flex-col items-center justify-center w-full md:w-4/5 lg:w-3/4 xl:w-2/3 2xl:w-1/2 p-4">
                        <div className="content">
                            <Button onClick={() => setIsModalOpen(true)}>Import Goodreads</Button>
                            <label className="w-full">
                                Username
                                <Input className="w-full" type="text" placeholder="Username" value={username} onChange={handleChange(setUsername, 15)} required />
                                <p>{username?.length}/15</p>
                            </label>
                            <label className="w-full mt-4 md:mt-0">
                                Bio
                                <TextAreaShadCN className="w-full" type="text" placeholder="Bio" value={bio} onChange={handleChange(setBio, 250)} />
                                <p>{bio?.length}/250</p>
                            </label>
                            <label className="w-full mt-4 md:mt-0">
                                Profile Picture
                                <input type="file" accept="image/*" className="w-full" onChange={e => setPfp(e.target.files[0])} />
                            </label>
                            <Button className="w-full md:w-auto mt-4 md:mt-0" onClick={handleSubmit} disabled={initialValues.username === username && initialValues.bio === bio && initialValues.pfp === pfp}>Submit</Button>
                            <Button className="w-full md:w-auto mt-4 md:mt-0" onClick={() => window.location.replace("/api/e/v1/auth/logout")}>
                                Logout
                            </Button>
                        </div>
                        <Onboard isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} altStep={altStep} redirectPath={redirectPath} isToReadModalOpen={isToReadModalOpen} isReadingModalOpen={isReadingModalOpen} isReviewModalOpen={isReviewModalOpen}/>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Settings;