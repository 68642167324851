import { useEffect, useState } from 'react';
import Loader from "../ui/Loader";

const DEFAULT_LOADING = false

function ShopifyBuyButton() {

  const [loading, setLoading] = useState(DEFAULT_LOADING);

  useEffect(() => {
    const scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
    let shopifyInitialized = false;

    function loadScript() {
      const script = document.createElement('script');
      script.async = true;
      script.src = scriptURL;
      document.head.appendChild(script);
      script.onload = ShopifyBuyInit;
    }

    function ShopifyBuyInit() {
      if (shopifyInitialized) return;
      shopifyInitialized = true;
      setLoading(true);

      const client = window.ShopifyBuy.buildClient({
        domain: 'e0d68a.myshopify.com',
        storefrontAccessToken: '5d6a9081aaafa6d5166ddbe64bbfab83',
      });

      window.ShopifyBuy.UI.onReady(client).then((ui) => {
        const container = document.getElementById('collection-component');
        container.innerHTML = '';

        const collectionComponent = ui.createComponent('collection', {
          id: '307882590407',
          node: container,
          moneyFormat: '%24%7B%7Bamount%7D%7D',
          options: {
            product: {
              styles: {
                product: {
                  '@media (min-width: 50%)': {
                    'max-width': '100%',
                    'flex': 'none',
                    'margin': 0,
                    'padding': '0px',
                    'display': 'flex',
                    'flex-direction': 'column',
                    'alignItems': 'center',
                  },
                  imgWrapper: {
                    height: 'auto',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'flex-start',
                  },
                  "text-align": "left",
                  img: {
                    position: 'relative',
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                  },
                },
                title: {
                  fontWeight: 'normal',
                  color: '#000000',
                },
                button: {
                  alignSelf: 'flex-start', // Ensures left alignment for the button
                  marginTop: '10px', // Space between image and button
                  padding: '5px 10px', // Adjust padding for left alignment
                  ':hover': {
                    'background-color': '#aa230a',
                  },
                  'background-color': '#bd270b',
                  ':focus': {
                    'background-color': '#aa230a',
                  },
                  'border-radius': '0px',
                  'padding-left': '20px',
                  'padding-right': '20px',
                  'padding-top': '8px',
                  'padding-bottom': '8px'
                },
              },
              buttonDestination: 'modal',
              contents: {
                options: false,
                title: false,
                price: false,
              },
              text: {
                button: 'Join',
              },
            },
            cart: {
              styles: {
                button: {
                  ':hover': {
                    'background-color': '#aa230a',
                  },
                  'background-color': '#bd270b',
                  ':focus': {
                    'background-color': '#aa230a',
                  },
                  'border-radius': '0px',
                },
              },
            },
            productSet: {
              styles: {
                products: {
                  display: 'flex', // Flexbox layout for horizontal alignment
                  'overflow-x': 'auto', // Enable horizontal scrolling
                  'scroll-snap-type': 'x mandatory', // Optional: for snap scrolling
                  '@media (min-width: 601px)': {
                    'flex-wrap': 'nowrap', // Prevent wrapping, force items to scroll horizontally
                    'margin-left': '0px',
                  },
                },
              },
            }
          },
        });

        const isSoldOut = true;
        const buttonText = isSoldOut ? 'Sold Out' : 'Join';
        const buttonElements = container.querySelectorAll('.shopify-buy__btn');

        buttonElements.forEach(button => {
          button.textContent = buttonText;
          button.disabled = isSoldOut;
          if (isSoldOut) {
            button.style.backgroundColor = '#ccc';
          }
        });

        setLoading(false);

      });
    }

    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      } else {
        loadScript();
      }
    } else {
      loadScript();        
    }
  }, []);

  return (
    <>
    <div
      id="collection-component"
      style={{
        display: 'flex',
        overflowX: 'auto',      // Ensure horizontal scroll is enabled
        overflowY: 'hidden',
        scrollSnapType: 'x mandatory',
        whiteSpace: 'nowrap',   // Prevents wrapping of child elements
        width: '100%',          // Optional: set a width if you want
      }}
    />
    {loading ?
      <Loader /> :
      <>
      </>
    }
    </>
  );
}

export default ShopifyBuyButton;


// import { useEffect } from 'react';

// function ShopifyBuyButton() {
//   useEffect(() => {
//     const scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';

//     function loadScript() {
//       const script = document.createElement('script');
//       script.async = true;
//       script.src = scriptURL;
//       document.head.appendChild(script);
//       script.onload = ShopifyBuyInit;
//     }

//     function ShopifyBuyInit() {
//       const client = window.ShopifyBuy.buildClient({
//         domain: 'e0d68a.myshopify.com',
//         storefrontAccessToken: '5d6a9081aaafa6d5166ddbe64bbfab83',
//       });

//       window.ShopifyBuy.UI.onReady(client).then((ui) => {
//         ui.createComponent('collection', {
//           id: '307882590407', // Collection ID from new code A
//           node: document.getElementById('collection-component'),
//           moneyFormat: '%24%7B%7Bamount%7D%7D',
//           options: {
//             product: {
//               styles: {
//                 product: {
//                   '@media (min-width: 601px)': {
//                     'max-width': 'calc(25% - 20px)',
//                     'margin-left': '20px',
//                     'margin-bottom': '50px',
//                     width: 'calc(25% - 20px)',
//                   },
//                   img: {
//                     height: 'calc(100% - 15px)',
//                     position: 'absolute',
//                     left: '0',
//                     right: '0',
//                     top: '0',
//                   },
//                   imgWrapper: {
//                     'padding-top': 'calc(75% + 15px)',
//                     position: 'relative',
//                     height: '0',
//                   },
//                 },
//                 title: {
//                   'font-weight': 'normal',
//                   color: '#000000',
//                 },
//                 button: {
//                   ':hover': {
//                     'background-color': '#aa230a',
//                   },
//                   'background-color': '#bd270b',
//                   ':focus': {
//                     'background-color': '#aa230a',
//                   },
//                   'border-radius': '0px',
//                   'padding-left': '5px',
//                   'padding-right': '5px',
//                 },
//               },
//               buttonDestination: 'modal',
//               contents: {
//                 options: false,
//               },
//               text: {
//                 button: 'Join meeting',
//               },
//             },
//             modalProduct: {
//               contents: {
//                 img: false,
//                 imgWithCarousel: true,
//                 button: false,
//                 buttonWithQuantity: true,
//               },
//               styles: {
//                 product: {
//                   '@media (min-width: 601px)': {
//                     'max-width': '100%',
//                     'margin-left': '0px',
//                     'margin-bottom': '0px',
//                   },
//                 },
//                 button: {
//                   ':hover': {
//                     'background-color': '#aa230a',
//                   },
//                   'background-color': '#bd270b',
//                   ':focus': {
//                     'background-color': '#aa230a',
//                   },
//                   'border-radius': '0px',
//                   'padding-left': '5px',
//                   'padding-right': '5px',
//                 },
//                 title: {
//                   'font-family': 'Helvetica Neue, sans-serif',
//                   'font-weight': 'bold',
//                   'font-size': '26px',
//                   color: '#4c4c4c',
//                 },
//               },
//               text: {
//                 button: 'Add to cart',
//               },
//             },
//             cart: {
//               styles: {
//                 button: {
//                   ':hover': {
//                     'background-color': '#aa230a',
//                   },
//                   'background-color': '#bd270b',
//                   ':focus': {
//                     'background-color': '#aa230a',
//                   },
//                   'border-radius': '0px',
//                 },
//                 title: { color: '#000000' },
//                 header: { color: '#000000' },
//               },
//               text: {
//                 total: 'Subtotal',
//                 notice: 'Discount codes can be added at checkout.',
//                 button: 'Checkout',
//               },
//             },
//           },
//         });
//       });
//     }

//     if (window.ShopifyBuy) {
//       if (window.ShopifyBuy.UI) {
//         ShopifyBuyInit();
//       } else {
//         loadScript();
//       }
//     } else {
//       loadScript();
//     }
//   }, []);

//   return <div id="collection-component" />;
// }

// export default ShopifyBuyButton;
