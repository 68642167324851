import React, { useEffect, useRef, useState } from "react";
import { redirect, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { Dialog, DialogContent, DialogActions } from "@mui/material";
import PhoneNumberInput from "../ui/inputs/PhoneNumberInput";
import VerificationCodeInput from "../ui/inputs/VerificationCodeInput";
import { Button } from "../ui/shadcn/Button";
import useStore from "../../store/store";
import Header from "../ui/layout/Header";
import * as amplitude from '@amplitude/analytics-browser';

const DEFAULT_PHONE_NUMBER = "";
const DEFAULT_VERIFICATION_CODE = ["", "", "", "", "", ""];
const DEFAULT_STEP_SEND_CODE = "sendCode";
const DEFAULT_STEP_VERIFY_CODE = "verifyCode";
const RESEND_TIMEOUT = 30000;
const DEFAULT_SCREEN_SET_STEP = 1;

const Auth = () => {
    const [phoneNumber, setPhoneNumber] = useState(DEFAULT_PHONE_NUMBER);
    const [verificationCode, setVerificationCode] = useState(DEFAULT_VERIFICATION_CODE);
    const [step, setStep] = useState(DEFAULT_STEP_SEND_CODE);
    const [resendDisabled, setResendDisabled] = useState(false);
    const [resendTimeout, setResendTimeout] = useState(RESEND_TIMEOUT / 1000);
    const { getUser } = useStore((state) => ({
        getUser: state.getUser
    }));
    const location = useLocation();
    const navigate = useNavigate();
    const resendTimer = useRef(null);
    const [screenStep, setScreenStep] = useState(DEFAULT_SCREEN_SET_STEP);

    useEffect(() => {
        if (resendDisabled && resendTimeout > 0) {
            resendTimer.current = setInterval(() => {
                setResendTimeout((prevTimeout) => prevTimeout - 1);
            }, 1000);
        } else if (!resendDisabled && resendTimeout !== RESEND_TIMEOUT / 1000) {
            clearInterval(resendTimer.current);
            setResendTimeout(RESEND_TIMEOUT / 1000);
        } else if (resendDisabled && resendTimeout === 0) {
            setResendDisabled(false);
        }
        return () => clearInterval(resendTimer.current);
    }, [resendDisabled, resendTimeout]);

    const handleSendVerificationCode = async () => {
        try {
            const { data, status } = await axios.post(`${process.env.REACT_APP_AWS_API_GATEWAY}/api/e/v1/auth/twilio/send_verification_code`, {
                phone_number: phoneNumber
            });
            if (status === 200) {
                const phone_number_formatted = data?.phone_number_formatted;
                const verification_status = data?.verification_status;
                setPhoneNumber(phone_number_formatted);
                if (verification_status === "pending") {
                    setStep(DEFAULT_STEP_VERIFY_CODE);
                    setResendDisabled(true);
                    setResendTimeout(RESEND_TIMEOUT / 1000);
                }
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            if (err.response.status === 429) {
                toast.error("Too many requests. Please try again later.");
            } else {
                toast.error("Error sending verification code");
            }
        }
    };

    const handleVerifyCode = async () => {
        try {
            const { data, status } = await axios.post(`${process.env.REACT_APP_AWS_API_GATEWAY}/api/e/v1/auth/twilio/verify_code`, {
                phone_number: phoneNumber,
                verification_code: verificationCode.join("")
            });
            if (status === 200) {
                const is_new_user = data?.is_new_user;
                const verification_check_status = data?.verification_check_status;
                if (verification_check_status === "approved") {
                    await getUser();
                    amplitude.track("Logged in");
                    const redirectPath = location.state?.from;
                    const isToReadModalOpen = location.state?.isToReadModalOpen;
                    const isReadingModalOpen = location.state?.isReadingModalOpen;
                    const isReviewModalOpen = location.state?.isReviewModalOpen;
                    if (is_new_user && isToReadModalOpen) {
                        navigate("/settings", { state: { redirectPath, isToReadModalOpen } });
                    }
                    else if (is_new_user && isReadingModalOpen) {
                        navigate("/settings", { state: { redirectPath, isReadingModalOpen } });
                    }
                    else if (is_new_user && isReviewModalOpen) {
                        navigate("/settings", { state: { redirectPath, isReviewModalOpen } });
                    }
                    else if (redirectPath && isToReadModalOpen) {
                        navigate(redirectPath, { state: { isToReadModalOpen } });
                    }
                    else if (redirectPath && isReadingModalOpen) {
                        navigate(redirectPath, { state: { isReadingModalOpen } });
                    }
                    else if (redirectPath && isReviewModalOpen) {
                        navigate(redirectPath, { state: { isReviewModalOpen } });
                    }
                    else {
                        navigate("/feed");
                    }
                }
            } else if (status === 401) {
                const verification_check_status = data?.verification_check_status;
                throw new Error({ err: verification_check_status, status });
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error verifying code");
        }
    };

    return (
        <div style={{ backgroundColor: "white", color: '#BD270B', minHeight: '100vh', padding: '24px' }}>
          <div className="logo-container pb-10">
            <Header />
          </div>
          <>
            {screenStep === 1 && (
                <>
                <div className="text-left">
                    <h1 className="text-2xl pb-5">keep the conversation going.</h1>
                    <h1 className="text-2xl pb-5">review the book we just read and everything  else you're reading*.</h1>
                    <h1 className="text-2xl pb-5">see what's on other members' shelves.</h1>
                    <h1 className="text-2xl">join the group chat.</h1>
                    <div style={{display: 'flex', justifyContent: 'center' }} className="pb-10 pt-10">
                    <Button style={{ backgroundColor: '#BD270B', color: 'white'}} className="primary-font text-2xl" onClick={() => setScreenStep(2)}>be an open book</Button>
                    </div>
                    <h1 className="pt-5 pb-5 text-xl"><i>*because you're so literate</i></h1>
                </div>
                </>
            )}
            {screenStep === 2 && (
                <> 
                        {step === "sendCode" ? (
                          <>
                            <h1 className="pb-5  text-white text-2xl libre-caslon text-center">Sign in or sign up</h1>
                            <PhoneNumberInput phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} />
                          </>
                        ) : (
                          <>
                            <h1 className="pb-5  text-white text-2xl libre-caslon text-center">Enter code</h1>
                            <VerificationCodeInput verificationCode={verificationCode} setVerificationCode={setVerificationCode} />
                          </>
                        )}
                      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                          {step === "sendCode" ? (
                            <Button style={{ backgroundColor: "#BD270B", color: 'white'}} onClick={handleSendVerificationCode} className="primary-font text-2xl">
                              Log in
                            </Button>
                          ) : (
                            <div className="flex-col-center-center gap">
                              <Button style={{ backgroundColor: '#BD270B', color: 'white'}} onClick={handleVerifyCode} className="primary-font text-2xl">
                                Verify Code
                              </Button>
                              {/* <Button className="button-secondary w-full mt-2" onClick={handleSendVerificationCode} disabled={resendDisabled}>
                                {resendDisabled ? `Resend Code (${resendTimeout}s)` : "Resend Code"}
                              </Button> */}
                              <p className="text-sm">By clicking Verify Code, you consent to receive text messages from us. Message and data rates apply. Text STOP to cancel.</p>
                            </div>
                          )}
                      </div>
                      </>
            )}
          </>

        </div>
      );
    };

export default Auth;
